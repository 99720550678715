import { useCallback, useEffect, useMemo, useState } from 'react';
import { TagsWithConsentSettings, usePlatformEventsTriggerOptions } from './hooks';
import { PlatformEventTriggerOptionValues, UpsertComplianceRulesConfig } from 'types';
import { toast } from 'react-toastify';
import { PLATFORM_EVENT_TRIGGER_OPTIONS, PLATFORM_EVENT_TRIGGER_OPTIONS_NAMES } from '../../../../constants/complianceRules';
import { Select } from '../../../../components/widgets/select';
import { SelectItem } from 'types';
import { useUpdateComplianceRules } from '../../../../services/hooks';
import styles from './styles.module.css';

export const PlatformEventTrigger = ({ network }: { network: number }) => {
    const { isLoading: isTagsLoading, triggerOptions, tagsWithConsentSettings } = usePlatformEventsTriggerOptions(network);

    const { mutate: upsertComplianceRules, isLoading: isUpserting, error: upsertError } = useUpdateComplianceRules(network);

    const isLoading = useMemo(() => isTagsLoading, [isTagsLoading, isUpserting]);

    const [initialState, setInitialState] = useState<TagsWithConsentSettings>({});
    const [currentState, setCurrentState] = useState<TagsWithConsentSettings>({});

    useEffect(() => {
        setInitialState({ ...tagsWithConsentSettings });
        setCurrentState({ ...tagsWithConsentSettings });
    }, [tagsWithConsentSettings]);

    const isStateChanged = useMemo(() => {
        return JSON.stringify(initialState) != JSON.stringify(currentState);
    }, [initialState, currentState]);

    const triggerOptionList = useMemo(() => Object.values(triggerOptions), [triggerOptions]);

    const onSelectTriggerOption = useCallback(
        (tagId: number, selected: SelectItem<PlatformEventTriggerOptionValues>) => {
            const shallowCopy = { ...currentState };
            shallowCopy[tagId] = { ...shallowCopy[tagId], selected };
            setCurrentState(shallowCopy);
        },
        [currentState]
    );

    const resetState = useCallback(() => {
        setCurrentState({ ...initialState });
    }, [initialState]);

    const onSave = useCallback(async () => {
        const upsertComplianceRulesModel = Object.keys(currentState).reduce<UpsertComplianceRulesConfig>((accumulator, tagId) => {
            if (currentState[tagId].selected.value != initialState[tagId].selected.value) {
                const config: UpsertComplianceRulesConfig[number] = {
                    consent: {
                        enabled: false,
                    },
                    threatGroup: {
                        enabled: false,
                    },
                };

                switch (currentState[tagId].selected.value) {
                    case PLATFORM_EVENT_TRIGGER_OPTIONS[PLATFORM_EVENT_TRIGGER_OPTIONS_NAMES.MALICIOUS]:
                        config.threatGroup.enabled = true;
                        break;
                    case PLATFORM_EVENT_TRIGGER_OPTIONS[PLATFORM_EVENT_TRIGGER_OPTIONS_NAMES.NONE]:
                        config.consent.enabled = true;
                        break;
                    case PLATFORM_EVENT_TRIGGER_OPTIONS[PLATFORM_EVENT_TRIGGER_OPTIONS_NAMES.ALL]:
                        break;
                }
                accumulator[tagId] = config;
            }
            return accumulator;
        }, {});

        await upsertComplianceRules(upsertComplianceRulesModel);

        if (upsertError) toast('Failed saving settings!', { type: 'error' });
        else {
            toast('Settings successfully saved!', { type: 'success' });
            setInitialState({ ...currentState });
        }
    }, [currentState, initialState]);

    return (
        <section className={styles.container}>
            <p>Acquisition | Platforms Event Trigger settings</p>
            {Object.entries(currentState)?.map(([tagId, { tagName, selected }]) => (
                <section className={styles.row} key={tagId}>
                    <label>{tagName}</label>
                    <Select
                        placeholder="Select event type"
                        options={triggerOptionList}
                        value={selected}
                        onChange={(selectedItem: SelectItem<PlatformEventTriggerOptionValues>) => onSelectTriggerOption(Number(tagId), selectedItem)}
                        isDisabled={isLoading}
                        isMulti={false}
                        styles={{
                            container: {
                                width: '65%',
                            },
                        }}
                    ></Select>
                </section>
            ))}
            <div>* Note: CHEQ detection data would be passed to the dataLayer object in cases where events were not set to trigger.</div>
            <div className={`${styles.row} ${styles['flex-end']}`}>
                <button className="cq-btn" onClick={resetState} disabled={!isStateChanged}>
                    Cancel
                </button>
                <button className="cq-btn" onClick={onSave} disabled={!isStateChanged}>
                    Save
                </button>
            </div>
        </section>
    );
};
